<script setup>
import { useLayoutStore } from '~/stores/layout.store';
import helper from '~/resources/js/libraries/helper';

defineProps({
  showBorder: {
    type: Boolean,
    default: true,
  }
});

const isOpen = ref(false);
const layoutStore = useLayoutStore();
const currencies = ref([]);

currencies.value = await layoutStore.fetchCurrencies();

const currentCurrency = helper.cookies.currency();

const changeCurrency = (currency) => {
  currentCurrency.value = currency;
}

watch(currentCurrency, () => window.location.href = window.location.href);

</script>

<template>
  <div class="w-full *:py-3  *:last-of-type:border-b-0" :class="{ '*:border-b': showBorder }">
    <button @click="isOpen = true" class="block w-full">
      <slot name="button-design"></slot>

      <template v-if="!$slots['button-design']">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-4">
            <i class="fa-solid fa-dollar-sign fa-fw text-indigo-900"></i>
            <span class="text-black text-xl">Currency</span>
          </div>
          <span class="text-base text-gray-600 ms-auto">{{ currentCurrency.code }}</span>
        </div>
      </template>
    </button>
  </div>
 
    <w-slideover v-model="isOpen" :isBottom="true">
      <div class="sort p-4">
        <h1 class="mb-3 text-2xl">Currency</h1>
        <div class="mt-6 space-y-3">
          <div class="flex items-center border-b pb-2" v-for="currency in currencies" :key="currency.code">
            <button @click="changeCurrency(currency)" class="w-full text-left">
              {{ currency.code }}   
            </button>
            <span v-if="currentCurrency.code == currency.code" class="fas fa-check inline-flex items-center justify-center w-10 h-10 text-black rounded-full text-sm"></span>
          </div>
        </div>
      </div>
    </w-slideover>
  
</template>
