<script setup>
import { useLayoutStore } from '~/stores/layout.store';
import { useMetaStore } from '~/stores/meta.store';

defineProps({
  showBorder: {
    type: Boolean,
    default: true,
  }
});

const isOpen = ref(false);
const { locale } = useI18n();
const languages = ref([]);
const currentLanguage = ref('');
const layoutStore = useLayoutStore();
const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags();
meta.value = metaStore.metaGetter();
metaStore.setMeta(meta.value);


currentLanguage.value = layoutStore.languages.find((language) => {
  return language.code.toLowerCase() === locale.value.toLowerCase();
})?.name;

languages.value = layoutStore.languages.map((language) => {
  return {
    code: language.code,
    name: language.name,
    url: metaStore.meta?.href_langs?.[language.code],
  }
}).filter((language) => {
  return !!language.url;
});
</script>

<template>
  <div class="w-full *:py-3  *:last-of-type:border-b-0" :class="{ '*:border-b ': showBorder }">
    <button @click="isOpen = true" class="block w-full overflow-hidden">
      <slot name="button-design"></slot>

      <template v-if="!$slots['button-design']">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-4">
            <i class="fa-solid fa-globe fa-fw text-indigo-900"></i>
            <span class="text-black text-xl">{{ $t('client.languages') }}</span>
          </div>
          <span class="text-base text-gray-600 ms-auto">{{ currentLanguage }}</span>
        </div>
      </template>
    </button>
  </div>

  <w-slideover v-model="isOpen" :isBottom="true">
    <div class="sort p-4">
      <h1 class="mb-3 text-2xl">{{ $t('client.languages') }}</h1>
      <div class="mt-6 space-y-3">
        <div class="flex items-center border-b pb-2" v-for="language in languages">
          <a :href="language.url" class="w-full text-left">
            {{ language.name ?? language.code }}
          </a>
          <span v-if="language.name == currentLanguage" class="fas fa-check inline-flex items-center justify-center w-10 h-10 text-black rounded-full text-sm"></span>
        </div>

      </div>
    </div>
  </w-slideover>
</template>
